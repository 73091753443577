module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm);
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<article class=\"grid-view-item\">\n    <a class=\"link\" href=\"<%= href %>\" aa-link=\"<%= aaLink %>\"\n        <% if(actionTarget) { %>\n            target=\"<%= actionTarget %>\"\n        <% } %>\n    >\n        <div class=\"thumb-wrapper\">\n            <img class=\"thumb lazy\"\n                 data-src=\"<%= thumb %>\"\n                 alt=\"<%= alt %>\"/>\n            <% if(icon){ %>\n                <div class=\"label <%= icon %> circle-sm\"></div>\n            <% } %>\n        </div>\n    </a>\n    <div class=\"info-wrapper\">\n        <span class=\"meta-category\">\n            <%= contentType %>\n        </span>\n        <div class=\"title-wrapper\">\n            <span class=\"title\">\n                <%= title %>\n            </span>\n        </div>\n    </div>\n</article>"
  , __filename = "src/js/templates/__whatsNew.tpl";
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  var __locals = (locals || {}),
data = __locals.data,
  selected = __locals.selected;
  with (locals || {}) {
    ; __append("<article class=\"grid-view-item\">\n    <a class=\"link\" href=\"")
    ; __line = 2
    ; __append(escapeFn( href ))
    ; __append("\" aa-link=\"")
    ; __append(escapeFn( aaLink ))
    ; __append("\"\n        ")
    ; __line = 3
    ;  if(actionTarget) { 
    ; __append("\n            target=\"")
    ; __line = 4
    ; __append(escapeFn( actionTarget ))
    ; __append("\"\n        ")
    ; __line = 5
    ;  } 
    ; __append("\n    >\n        <div class=\"thumb-wrapper\">\n            <img class=\"thumb lazy\"\n                 data-src=\"")
    ; __line = 9
    ; __append(escapeFn( thumb ))
    ; __append("\"\n                 alt=\"")
    ; __line = 10
    ; __append(escapeFn( alt ))
    ; __append("\"/>\n            ")
    ; __line = 11
    ;  if(icon){ 
    ; __append("\n                <div class=\"label ")
    ; __line = 12
    ; __append(escapeFn( icon ))
    ; __append(" circle-sm\"></div>\n            ")
    ; __line = 13
    ;  } 
    ; __append("\n        </div>\n    </a>\n    <div class=\"info-wrapper\">\n        <span class=\"meta-category\">\n            ")
    ; __line = 18
    ; __append(escapeFn( contentType ))
    ; __append("\n        </span>\n        <div class=\"title-wrapper\">\n            <span class=\"title\">\n                ")
    ; __line = 22
    ; __append(escapeFn( title ))
    ; __append("\n            </span>\n        </div>\n    </div>\n</article>")
    ; __line = 26
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

//# sourceURL="src/js/templates/__whatsNew.tpl"

}