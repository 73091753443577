module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm);
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<article class=\"grid-view-item\">\n\n        <div class=\"thumb-wrapper\">\n            <img class=\"thumb lazy\" data-src=\"<%= thumbUrl %>\" alt=\"<%= show_title %> - <%= title %>\"/>\n        </div>\n\n    <meta itemprop=\"name\" content=\"<%= title %>\"/>\n    <meta itemprop=\"description\" content=\"<%= alt_title %>\"/>\n    <div class=\"info-wrapper\">\n        <div class=\"title-wrapper\">\n            <span class=\"title\">\n                    <%= title %>\n            </span>\n        </div>\n    </div>\n</article>"
  , __filename = "src/js/templates/__showPhotos.tpl";
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  var __locals = (locals || {}),
data = __locals.data,
  selected = __locals.selected;
  with (locals || {}) {
    ; __append("<article class=\"grid-view-item\">\n\n        <div class=\"thumb-wrapper\">\n            <img class=\"thumb lazy\" data-src=\"")
    ; __line = 4
    ; __append(escapeFn( thumbUrl ))
    ; __append("\" alt=\"")
    ; __append(escapeFn( show_title ))
    ; __append(" - ")
    ; __append(escapeFn( title ))
    ; __append("\"/>\n        </div>\n\n    <meta itemprop=\"name\" content=\"")
    ; __line = 7
    ; __append(escapeFn( title ))
    ; __append("\"/>\n    <meta itemprop=\"description\" content=\"")
    ; __line = 8
    ; __append(escapeFn( alt_title ))
    ; __append("\"/>\n    <div class=\"info-wrapper\">\n        <div class=\"title-wrapper\">\n            <span class=\"title\">\n                    ")
    ; __line = 12
    ; __append(escapeFn( title ))
    ; __append("\n            </span>\n        </div>\n    </div>\n</article>")
    ; __line = 16
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

//# sourceURL="src/js/templates/__showPhotos.tpl"

}