module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm);
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "\n<article class=\"grid-view-item\">\n    <a class=\"link\"\n       href=\"<%= url %>\"\n       aa-link=\"<%= aaLink %>\"\n        <% if (typeof dataTracking !== 'undefined') {  %>\n            data-tracking=\"<%= dataTracking %>\">\n        <% } %>\n    >\n        <div class=\"thumb-wrapper\">\n\n            <img class=\"thumb lazy\" data-src=\"<%= thumbUrl %>\"\n                 alt=\"<%= title %>\"\n            />\n        </div>\n    </a>\n\n    <div class=\"info-wrapper\">\n\n\n        <%# HOST %>\n        <% if(bioType == 'host'){ %>\n        <div class=\"title-wrapper\">\n            <span class=\"meta-gray\">\n                Host\n            </span>\n        </div>\n        <% } %>\n\n\n        <%# CHARACTER %>\n        <% if(characterName){ %>\n        <div class=\"title-wrapper\">\n            <span class=\"meta-gray\">\n                <%= characterName %>\n            </span>\n        </div>\n        <% } %>\n\n\n\n        <%# TITLE %>\n        <% if(title){ %>\n        <div class=\"title-wrapper\">\n            <span class=\"title\">\n                <%= title %>\n            </span>\n        </div>\n        <% } %>\n\n    </div>\n</article>"
  , __filename = "src/js/templates/__cast.tpl";
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  var __locals = (locals || {}),
data = __locals.data,
  selected = __locals.selected;
  with (locals || {}) {
    ; __append("\n<article class=\"grid-view-item\">\n    <a class=\"link\"\n       href=\"")
    ; __line = 4
    ; __append(escapeFn( url ))
    ; __append("\"\n       aa-link=\"")
    ; __line = 5
    ; __append(escapeFn( aaLink ))
    ; __append("\"\n        ")
    ; __line = 6
    ;  if (typeof dataTracking !== 'undefined') {  
    ; __append("\n            data-tracking=\"")
    ; __line = 7
    ; __append(escapeFn( dataTracking ))
    ; __append("\">\n        ")
    ; __line = 8
    ;  } 
    ; __append("\n    >\n        <div class=\"thumb-wrapper\">\n\n            <img class=\"thumb lazy\" data-src=\"")
    ; __line = 12
    ; __append(escapeFn( thumbUrl ))
    ; __append("\"\n                 alt=\"")
    ; __line = 13
    ; __append(escapeFn( title ))
    ; __append("\"\n            />\n        </div>\n    </a>\n\n    <div class=\"info-wrapper\">\n\n\n        ")
    ; __line = 21
    ; __append("\n        ")
    ; __line = 22
    ;  if(bioType == 'host'){ 
    ; __append("\n        <div class=\"title-wrapper\">\n            <span class=\"meta-gray\">\n                Host\n            </span>\n        </div>\n        ")
    ; __line = 28
    ;  } 
    ; __append("\n\n\n        ")
    ; __line = 31
    ; __append("\n        ")
    ; __line = 32
    ;  if(characterName){ 
    ; __append("\n        <div class=\"title-wrapper\">\n            <span class=\"meta-gray\">\n                ")
    ; __line = 35
    ; __append(escapeFn( characterName ))
    ; __append("\n            </span>\n        </div>\n        ")
    ; __line = 38
    ;  } 
    ; __append("\n\n\n\n        ")
    ; __line = 42
    ; __append("\n        ")
    ; __line = 43
    ;  if(title){ 
    ; __append("\n        <div class=\"title-wrapper\">\n            <span class=\"title\">\n                ")
    ; __line = 46
    ; __append(escapeFn( title ))
    ; __append("\n            </span>\n        </div>\n        ")
    ; __line = 49
    ;  } 
    ; __append("\n\n    </div>\n</article>")
    ; __line = 52
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

//# sourceURL="src/js/templates/__cast.tpl"

}